<template>
  <v-row
    no-gutters
    class="mx-4 py-3"
  >
    <v-data-table
      v-if="fetchedReport.data"
      :headers="dataTableHeaders"
      :items="getItems()"
      :items-per-page="fetchedReport.tableConfig.itemsPerPage || 10"
      :footer-props="{
        itemsPerPageOptions: config.dataTableItemsPerPageOptions
      }"
      class="elevation-1 flex"
      dense
    >
      <template
        v-if="fetchedReport.data.length > 0"
        slot="body.append"
      >
        <tr v-if="!isMobile">
          <td
            v-for="(sum,index) in fetchedReport.summaryCol"
            :key="sum + '-' + index"
            class="summary-cell text-no-wrap"
            v-html="formatSumCellContent(sum)"
          />
        </tr>
      </template>
      <template #[`item.mapRoutes`]="{ item }">
        <v-btn
          icon
          @click="openDialog(item)"
          :disabled="('lat' in item && !item.lat && !item.lng) || ('latitude' in item && !item.longitude && !item.latitude)">
          <v-icon>mdi-map-marker-radius</v-icon>
        </v-btn>
      </template>
      <template #[`item.totalVehicleExpenses`]="{ item }">
        <td
          class="text-no-wrap"
          v-html="formatSumCellContent(item.totalVehicleExpenses)"
        />
      </template>
      <template #[`item.expenseByKm`]="{ item }">
        <td
          class="text-no-wrap"
          v-html="formatSumCellContent(item.expenseByKm)"
        />
      </template>
      <template #[`item.address`]="{ item }">
        <td
          class="text-no-wrap"
        >
          <reverse-geocoding-field
            :key="`reverse-geocoding-field-${item.vehicleId}-${item.lat}-${item.lng}`"
            :address="item.address"
            :lat-lng="{ lat: item.lat, lng: item.lng }"
            :vehicle-id="item.vehicleId"
            :with-user-locations="type === 'stops'"
            :text-alignment="'center'"
            :loading-indicator-alignment="'center'"
          />
        </td>
      </template>
      <template #[`item.startAddress`]="{ item }">
        <reverse-geocoding-field
          :key="`reverse-geocoding-field-${item.vehicleId}-${item.startLat}-${item.startLng}`"
          :address="item.startAddress"
          :lat-lng="{ lat: item.startLat, lng: item.startLng }"
          :vehicle-id="item.vehicleId"
          :text-alignment="'center'"
          :loading-indicator-alignment="'center'"
        />
      </template>
      <template #[`item.endAddress`]="{ item }">
        <reverse-geocoding-field
          :key="`reverse-geocoding-field-${item.key}`"
          :address="item.endAddress"
          :lat-lng="{ lat: item.endLat, lng: item.endLng }"
          :vehicle-id="item.vehicleId"
          :text-alignment="'center'"
          :loading-indicator-alignment="'center'"
        />
      </template>
    </v-data-table>
    <map-route-overview-modal
      class="mr-4"
      :key="mapRouteKey"
      :show-dialog="routeDialogOpen"
      :circleMarker="circleMarker"
      :vehicleId="clickedVehicleId"
      :tooltip="tooltip"
      :markerRadius=radius
      :route-data="routeData"
      @close-dialog="closeDialog"
    />
    <location-map-modal
      :location-dialog="showSpeedingReportMapDialog"
      :geolocation="coords"
      :show-address-on-marker-hover="false"
      :dialog-title="$t('locations.location')"
      @close-dialog="closeSpeedingReportsMap"
    />
  </v-row>
</template>

<script>
import { forEach, upperFirst } from 'lodash'
import MapRouteOverviewModal from '@/global/components/modals/MapRouteOverviewModal'
import dataTableConfig from '@/global/common/dataTableConfig'
import ReverseGeocodingField from '@/global/components/geocoding/ReverseGeocodingField'
import LocationMapModal from '@/global/components/modals/LocationMapModal'

export default {
  name: 'SingleDeviceReportTable',

  components: {
    LocationMapModal,
    MapRouteOverviewModal,
    ReverseGeocodingField
  },

  props: {
    fetchedReport: {
      type: Object,
      required: true,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'default'
    }
  },

  data () {
    return {
      isMobile: false,
      config: dataTableConfig,
      routeDialogOpen: false,
      clickedItemVehicleType: null,
      routeData: [],
      circleMarker: [],
      tooltip: '',
      mapRouteKey: 0,
      coords: null,
      showSpeedingReportMapDialog: false,
      clickedVehicleId: null
    }
  },

  computed: {
    dataTableHeaders () {
      const headers = []

      if ('reportColumnConfig' in this.fetchedReport) {
        forEach(this.fetchedReport.reportColumnConfig, function (column, key) {
          headers.push({
            text: upperFirst(column.label),
            value: key
          })
        })
      }
      return headers
    },
    radius () {
      return parseInt(this.fetchedReport.markerRadius) ?? 50
    }
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    getItems () {
      if (Array.isArray(this.fetchedReport.data)) {
        return this.fetchedReport.data
      }
      return []
    },

    async openDialog (item) {
      this.clickedVehicleId = item.vehicleId || null
      if (this.type === 'speedingAbsolute' || this.type === 'speedingRoad' || this.type === 'stops') {
        this.showSpeedingReportMapDialog = true
        this.coords = item
      }
      else {
        const body = {
          vehicle_id: item.vehicleId,
          from: item.startTimeUTC,
          timezone: item.timezone,
          startTime: item.startTime,
          endTime: item.endTime,
          latitude: item.latitude,
          longitude: item.longitude
        }
        fetch('/api/satellite-tracking/get-trip-by-dateTime-and-vehicleId-parameters', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => {
            if (!res.ok) {
              console.log(res)
            }
            else {
              return res.json()
            }
          })
          .then(data => {
            this.clickedItemVehicleType = item.vehicleType || null
            this.routeData = data
            this.circleMarker = [item.latitude, item.longitude]
            this.tooltip = item.retentionTime
            this.routeDialogOpen = true
          })
          .catch(error => {
            console.log(error)
            this.clickedItemVehicleType = null
            this.routeData = []
            this.circleMarker = []
            this.tooltip = ''
            this.routeDialogOpen = false
          })
      }
    },

    closeDialog () {
      this.clickedItemVehicleType = null
      this.routeData = []
      this.circleMarker = []
      this.tooltip = ''
      this.routeDialogOpen = false
      this.mapRouteKey++
    },

    closeSpeedingReportsMap () {
      this.showSpeedingReportMapDialog = false
    },

    onResize () {
      this.isMobile = window.innerWidth < 600
    },

    formatSumCellContent (sum) {
      if (typeof sum === 'object') {
        return Object.entries(sum)
          .map(([key, value]) => `${key}: ${value}`)
          .join('<br>')
      }

      return sum
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 768px) {
  .v-data-table {
    > .v-data-table__wrapper {
      table {
        tbody {
          display: flex;
          flex: 1;
          tr {
            display: flex;
            td {
              display: flex;
              &.summary-cell {
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
