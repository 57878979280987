var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-4 py-3",attrs:{"no-gutters":""}},[(_vm.fetchedReport.data)?_c('v-data-table',{staticClass:"elevation-1 flex",attrs:{"headers":_vm.dataTableHeaders,"items":_vm.getItems(),"items-per-page":_vm.fetchedReport.tableConfig.itemsPerPage || 10,"footer-props":{
      itemsPerPageOptions: _vm.config.dataTableItemsPerPageOptions
    },"dense":""},scopedSlots:_vm._u([{key:"item.mapRoutes",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":('lat' in item && !item.lat && !item.lng) || ('latitude' in item && !item.longitude && !item.latitude)},on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-icon',[_vm._v("mdi-map-marker-radius")])],1)]}},{key:"item.totalVehicleExpenses",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(_vm.formatSumCellContent(item.totalVehicleExpenses))}})]}},{key:"item.expenseByKm",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(_vm.formatSumCellContent(item.expenseByKm))}})]}},{key:"item.address",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-no-wrap"},[_c('reverse-geocoding-field',{key:("reverse-geocoding-field-" + (item.vehicleId) + "-" + (item.lat) + "-" + (item.lng)),attrs:{"address":item.address,"lat-lng":{ lat: item.lat, lng: item.lng },"vehicle-id":item.vehicleId,"with-user-locations":_vm.type === 'stops',"text-alignment":'center',"loading-indicator-alignment":'center'}})],1)]}},{key:"item.startAddress",fn:function(ref){
    var item = ref.item;
return [_c('reverse-geocoding-field',{key:("reverse-geocoding-field-" + (item.vehicleId) + "-" + (item.startLat) + "-" + (item.startLng)),attrs:{"address":item.startAddress,"lat-lng":{ lat: item.startLat, lng: item.startLng },"vehicle-id":item.vehicleId,"text-alignment":'center',"loading-indicator-alignment":'center'}})]}},{key:"item.endAddress",fn:function(ref){
    var item = ref.item;
return [_c('reverse-geocoding-field',{key:("reverse-geocoding-field-" + (item.key)),attrs:{"address":item.endAddress,"lat-lng":{ lat: item.endLat, lng: item.endLng },"vehicle-id":item.vehicleId,"text-alignment":'center',"loading-indicator-alignment":'center'}})]}}],null,true)},[(_vm.fetchedReport.data.length > 0)?_c('template',{slot:"body.append"},[(!_vm.isMobile)?_c('tr',_vm._l((_vm.fetchedReport.summaryCol),function(sum,index){return _c('td',{key:sum + '-' + index,staticClass:"summary-cell text-no-wrap",domProps:{"innerHTML":_vm._s(_vm.formatSumCellContent(sum))}})}),0):_vm._e()]):_vm._e()],2):_vm._e(),_c('map-route-overview-modal',{key:_vm.mapRouteKey,staticClass:"mr-4",attrs:{"show-dialog":_vm.routeDialogOpen,"circleMarker":_vm.circleMarker,"vehicleId":_vm.clickedVehicleId,"tooltip":_vm.tooltip,"markerRadius":_vm.radius,"route-data":_vm.routeData},on:{"close-dialog":_vm.closeDialog}}),_c('location-map-modal',{attrs:{"location-dialog":_vm.showSpeedingReportMapDialog,"geolocation":_vm.coords,"show-address-on-marker-hover":false,"dialog-title":_vm.$t('locations.location')},on:{"close-dialog":_vm.closeSpeedingReportsMap}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }